const tokens = {
  pls: {
    symbol: 'PLS',
    projectLink: 'https://www.pulsechain.com/',
  },
  pine: {
    symbol: 'PINE',
    address: {
      369: '0xe846884430d527168b4eaac80af9268515d2f0cc',
    },
    decimals: 18,
    projectLink: 'https://pine.finance/',
  },
  wpls: {
    symbol: 'WPLS',
    address: {
      369: '0xa1077a294dde1b09bb078844df40758a5d0f9a27',
    },
    decimals: 18,
    projectLink: 'https://www.pulsechain.com/',
  },
  atropa: {
    symbol: 'ATROPA',
    address: {
      369: '0xCc78A0acDF847A2C1714D2A925bB4477df5d48a6',
    },
    decimals: 18,
    projectLink: 'https://www.pulsechain.com/',
  },
  dai: {
    symbol: 'DAI',
    address: {
      369: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
    },
    decimals: 18,
    projectLink: 'https://www.dai.com/',
  },
  wbtc: {
    symbol: 'WBTC',
    address: {
      369: '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
    },
    decimals: 18,
    projectLink: 'https://www.dai.com/',
  },
  plsx: {
    symbol: 'PLSX',
    address: {
      369: '0x95B303987A60C71504D99Aa1b13B4DA07b0790ab',
    },
    decimals: 18,
    projectLink: 'https://www.dai.com/',
  },
  paave: {
    symbol: 'pAAVE',
    address: {
    369: '0x7Fc66500c84A76Ad7e9c93437bFc5Ac33E2DDaE9',
    },
    decimals: 18,
  },
  plink: {
    symbol: 'pLINK',
    address: {
    369: '0x514910771AF9Ca656af840dff83E8264EcF986CA',
    },
    decimals: 18,
  },
  pmkr: {
    symbol: 'pMKR',
    address: {
    369: '0x9f8F72aA9304c8B593d555F12eF6589cC3A579A2',
    },
    decimals: 18,
  },
  ehex: {
    symbol: 'eHEX',
    address: {   
    369: '0x57fde0a71132198BBeC939B98976993d8D89D225',
    },
    decimals: 18,
  },
  pusdc: {
    symbol: 'pUSDC',
    address: {   
    369: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    },
    decimals: 18,
  },
  pusdt: {
    symbol: 'pUSDT',
    address: {   
    369: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
    },
    decimals: 18,
  },
  pweth: {
    symbol: 'pWETH',
    address: {   
    369: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    },
    decimals: 18,
  },
  bear: {
    symbol: 'BEAR',
    address: {   
    369: '0xd6c31bA0754C4383A41c0e9DF042C62b5e918f6d',
    },
    decimals: 18,
  },
    down: {
    symbol: 'dOWN',
    address: {   
    369: '0x2556F7f8d82EbcdD7b821b0981C38D9dA9439CdD',
    },
    decimals: 18,
  },
    tsfi: {
    symbol: 'TSFi',
    address: {   
    369: '0x4243568Fa2bbad327ee36e06c16824cAd8B37819',
    },
    decimals: 18,
  },
}

export default tokens
