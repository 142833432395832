import BigNumber from 'bignumber.js'
import erc20 from 'config/abi/erc20.json'
import masterchefABI from 'config/abi/masterchef.json'
import multicall from 'utils/multicall'
import { getAddress, getMasterChefAddress } from 'utils/addressHelpers'
import farmsConfig from 'config/constants/farms'
import { getFarmApy } from 'utils/apy'


const FetchFarms = async () => {
  const response1 = await fetch(`https://api.dexscreener.com/latest/dex/pairs/pulsechain/0xa1ce806c501b08072bdfe48d50c3d84b3c5a4fc3`)
  const rsps1 = await response1.json()

  let cakePrice = new BigNumber(0);

  if(rsps1.pair) cakePrice = new BigNumber(rsps1.pair.priceUsd);

  const data = await Promise.all(
    farmsConfig.map(async (farmConfig) => {
      const response = await fetch(`https://api.dexscreener.com/latest/dex/pairs/pulsechain/${farmConfig.lpAddresses[369]}`)
      const rsps = await response.json()
      let poolLiquidityUsd = new BigNumber(0);
     
  
      
      const lpAddress = getAddress(farmConfig.lpAddresses)
      const calls = [
        // Balance of token in the LP contract
        {
          address: getAddress(farmConfig.token.address),
          name: 'balanceOf',
          params: [lpAddress],
        },
        // Balance of quote token on LP contract
        {
          address: getAddress(farmConfig.quoteToken.address),
          name: 'balanceOf',
          params: [lpAddress],
        },
        // Balance of LP tokens in the master chef contract
        {
          address: lpAddress,
          name: 'balanceOf',
          params: [getMasterChefAddress()],
        },
        // Total supply of LP tokens
        {
          address: lpAddress,
          name: 'totalSupply',
        },
        // Token decimals
        {
          address: getAddress(farmConfig.token.address),
          name: 'decimals',
        },
        // Quote token decimals
        {
          address: getAddress(farmConfig.quoteToken.address),
          name: 'decimals',
        },
        {
          address: "0xE846884430D527168B4EAaC80Af9268515D2f0CC",
          name: 'balanceOf',
          params: [lpAddress],
        },
      ]

      const [
        tokenBalanceLP,
        quoteTokenBlanceLP,
        lpTokenBalanceMC,
        lpTotalSupply,
        tokenDecimals,
        quoteTokenDecimals,
        pineBalance,
      ] = await multicall(erc20, calls)

      if(rsps.pair && rsps.pair.liquidity){
        poolLiquidityUsd = new BigNumber(rsps.pair.liquidity.usd) 
      } else {
        console.log(pineBalance, "PINAAAAA")
        poolLiquidityUsd = new BigNumber(pineBalance).div(1e18).times(2).times(cakePrice)
      }
      


      // Ratio in % a LP tokens that are in staking, vs the total number in circulation
      const lpTokenRatio = new BigNumber(lpTokenBalanceMC).div(new BigNumber(lpTotalSupply))

      // Total value in staking in quote token value
      const lpTotalInQuoteToken = new BigNumber(quoteTokenBlanceLP)
        .div(new BigNumber(10).pow(18))
        .times(new BigNumber(2))
        .times(lpTokenRatio)
      const lpPriceUSD = poolLiquidityUsd.times(new BigNumber(10).pow(18)).div(lpTotalSupply)
      console.log('logger', lpPriceUSD)
      // Amount of token in the LP that are considered staking (i.e amount of token * lp ratio)
      const tokenAmount = new BigNumber(tokenBalanceLP).div(new BigNumber(10).pow(tokenDecimals)).times(lpTokenRatio)
      const quoteTokenAmount = new BigNumber(quoteTokenBlanceLP)
        .div(new BigNumber(10).pow(quoteTokenDecimals))
        .times(lpTokenRatio)

      const [info, totalAllocPoint] = await multicall(masterchefABI, [
        {
          address: getMasterChefAddress(),
          name: 'poolInfo',
          params: [farmConfig.pid],
        },
        {
          address: getMasterChefAddress(),
          name: 'totalAllocPoint',
        },
      ])

      const allocPoint = new BigNumber(info.allocPoint._hex)
      const fees = new BigNumber(info.taxFee._hex)
      const poolWeight = allocPoint.div(new BigNumber(totalAllocPoint))
      console.log('START DUBUGGG TV L 11', allocPoint.toNumber(), new BigNumber(totalAllocPoint).toNumber())

      const tvl = new BigNumber((Number(lpTokenBalanceMC)/Number(lpTotalSupply))*Number(poolLiquidityUsd))
      console.log('START DUBUGGG TVL', poolWeight.toNumber(), cakePrice.toNumber(), tvl.toNumber())
      return {
        ...farmConfig,
        tokenAmount: tokenAmount.toJSON(),
        quoteTokenAmount: quoteTokenAmount.toJSON(),
        lpTotalInQuoteToken: lpTotalInQuoteToken.toJSON(),
        tokenPriceVsQuote: quoteTokenAmount.div(tokenAmount).toJSON(),
        poolWeight: poolWeight.toJSON(),
        multiplier: `${allocPoint.div(10).toString()}X`,
        feesPercent:  `${fees.div(10000).toString()}%`,
        tvl,
        apy: getFarmApy(poolWeight, cakePrice, tvl),
        liquidity: tvl,
        cakePrice,
        lpPriceUSD,
      }
    }),
  )
  return data
}

export default FetchFarms
