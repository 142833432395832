import { MenuEntry } from '@atropine/uikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    items: [
      {
        label: 'Exchange',
        href: 'https://app.pulsex.com/swap?inputCurrency=0xCc78A0acDF847A2C1714D2A925bB4477df5d48a6&outputCurrency=0xE846884430D527168B4EAaC80Af9268515D2f0CC', // change token
      },
      {
        label: 'Liquidity',
        href: 'https://app.pulsex.com/add/V1/0xe846884430d527168b4eaac80af9268515d2f0cc/0xCc78A0acDF847A2C1714D2A925bB4477df5d48a6', // change token
      },
    ],
  },
  {
    label: 'Farms',
    icon: 'FarmIcon',
    href: '/farms',
  },
  {
    label: 'Info',
    icon: 'InfoIcon',
    items: [
      {
        label: 'Pulsex',
        href: 'https://app.pulsex.com/info/token/0xe846884430d527168b4eaac80af9268515d2f0cc',
      },
      {
        label: 'Dexscreener',
        href: 'https://dexscreener.com/pulsechain/0x0e4b3d3141608ebc730ee225666fd97c833d553e',
      },
      {
        label: 'Pairs',
        href: 'https://app.pulsex.com/info/token/0xe846884430d527168b4eaac80af9268515d2f0cc',
      },

    ],
  },
  {
    label: 'More',
    icon: 'MoreIcon',
    items: [
      {
        label: 'GitBook',
        href: 'https://atropine.gitbook.io/atropine/ ',
      },
      {
        label: 'Audit Report',
        href: 'https://atropine.gitbook.io/atropine/specs/security/audits ',
      },
    ],
  },
]

export default config
