export default {
  masterChef: {
    369: '0x6A621e240BA80B90090981D71E535C5148EFA450',
  },
  sousChef: {
    369: '0x6ab8463a4185b80905e05a9ff80a2d6b714b9e95',
  },
  lottery: {
    369: '0x3C3f2049cc17C136a604bE23cF7E42745edf3b91',
  },
  lotteryNFT: {
    369: '0x5e74094Cd416f55179DBd0E45b1a8ED030e396A1',
  },
  mulltiCall: {
    369: '0xEEb1D16A155741cE0eB14B5667D1dA65E8d5c565'
  },
  pancakeProfile: {
    369: '0xDf4dBf6536201370F95e06A0F8a7a70fE40E388a'
  },
  pancakeRabbits: {
    369: '0xDf7952B35f24aCF7fC0487D01c8d5690a60DBa07'
  },
  bunnyFactory: {
    369: '0xfa249Caa1D16f75fa159F7DFBAc0cC5EaB48CeFf'
  },
  claimRefund: {
    369: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149'
  },
  pointCenterIfo: {
    369: '0x3C6919b132462C1FEc572c6300E83191f4F0012a'
  },
  bunnySpecial: {
    369: '0xFee8A195570a18461146F401d6033f5ab3380849'
  },
}
